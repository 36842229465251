const dataCreateSlots: any = {
  daysOfWeek: [{ title: 'message.calendar.all_days', value: 'all_days' },
    { title: 'message.calendar.monday', value: 'monday' },
    { title: 'message.calendar.tuesday', value: 'tuesday' },
    { title: 'message.calendar.wednesday', value: 'wednesday' },
    { title: 'message.calendar.thursday', value: 'thursday' },
    { title: 'message.calendar.friday', value: 'friday' },
    { title: 'message.calendar.saturday', value: 'saturday' },
    { title: 'message.calendar.sunday', value: 'sunday' }],
  beginningTime: [{
    key: 'all_days',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  },
  {
    key: 'monday',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  },
  {
    key: 'tuesday',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  },
  {
    key: 'wednesday',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  },
  {
    key: 'thursday',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  },
  {
    key: 'friday',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  },
  {
    key: 'saturday',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  },
  {
    key: 'sunday',
    time: [
      { title: '10:00', value: '10:00' },
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' }]
  }
  ],
  duration: [{
    key: 'all_days',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  },
  {
    key: 'monday',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  },
  {
    key: 'tuesday',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  },
  {
    key: 'wednesday',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  },
  {
    key: 'thursday',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  },
  {
    key: 'friday',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  },
  {
    key: 'saturday',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  },
  {
    key: 'sunday',
    time: [
      { title: '30', value: '30' },
      { title: '60', value: '60' }]
  }
  ],
  endTime: [{
    key: 'all_days',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  },
  {
    key: 'monday',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  },
  {
    key: 'tuesday',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  },
  {
    key: 'wednesday',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  },
  {
    key: 'thursday',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  },
  {
    key: 'friday',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  },
  {
    key: 'saturday',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  },
  {
    key: 'sunday',
    time: [
      { title: '11:00', value: '11:00' },
      { title: '12:00', value: '12:00' },
      { title: '13:00', value: '13:00' },
      { title: '14:00', value: '14:00' },
      { title: '15:00', value: '15:00' },
      { title: '16:00', value: '16:00' },
      { title: '17:00', value: '17:00' },
      { title: '18:00', value: '18:00' },
      { title: '19:00', value: '19:00' },
      { title: '20:00', value: '20:00' },
      { title: '21:00', value: '21:00' }]
  }
  ]
}

export default dataCreateSlots

<template>
  <div class="calendar">
    <div class="header">
      <button
        @click="prevMonth"
        class="prev"
      >
        <Arrow />
      </button>
      <h2>{{ this.monthName }} {{ this.year }}</h2>
      <button @click="nextMonth">
        <Arrow />
      </button>
    </div>
    <div class="days-of-week">
      <div
        v-for="day in daysOfWeek"
        :key="day"
      >{{ day }}</div>
    </div>
    <div class="dates">
      <div
        v-for="date in dates"
        :key="date.key"
      >
        <div
          v-if="date.value > 0"
          class="days"
          @click="selectDate(date, this.month, this.year)"
        >
          <div
            @click="selectDateFill(date)"
            class="daysWrap"
            :class="{today: date.value === today && this.currentMonth === this.month && this.year === this.currentYear, ...date.classes, selected: selectedDate && selectedDate.getDate() === date.value && this.month === this.selectedMonth && this.year === this.selectedYear || date.value === new Date(selectedNewDate).getDate() && this.month === new Date(selectedNewDate).getMonth() && this.year === new Date(selectedNewDate).getFullYear(), disabled: new Date(this.year, this.month, date.value) < new Date() && date.value !== today}"
          >
            {{ date.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/svg/arrow-calendar.svg?inline'

export default {
  data () {
    return {
      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      month: '',
      monthName: '',
      year: '',
      dates: [],
      today: '',
      selectedDate: null,
      currentMonth: null,
      selectedMonth: null,
      currentYear: null,
      selectedYear: null
    }
  },
  props: {
    selectDate: {
      type: Function,
      default () {
        return {}
      }
    },
    selectedNewDate: String
  },
  components: {
    Arrow
  },
  methods: {
    selectDateFill (date) {
      if (date.value !== '') {
        const selectedDate = new Date(this.year, this.month, date.value)
        const today = new Date()
        if (selectedDate === today) {
          return
        }
        this.selectedDate = selectedDate
        this.selectedYear = this.year
        this.selectedMonth = this.month
      }
    },
    prevMonth () {
      if (this.month === 0) {
        this.month = 11
        this.year--
      } else {
        this.month--
      }
      this.dates = []
      this.updateDates()
    },
    nextMonth () {
      if (this.month === 11) {
        this.month = 0
        this.year++
      } else {
        this.month++
      }
      this.dates = []
      this.updateDates()
    },
    updateDates () {
      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate()
      let firstDayOfMonth = new Date(this.year, this.month, 1).getDay()
      while (firstDayOfMonth !== 1) {
        this.dates.push({ value: '', classes: 'prev-month' })
        firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1
      }
      const lastDayOfMonth = new Date(this.year, this.month, daysInMonth).getDay()
      // const daysAfterMonth = lastDayOfMonth === 0 ? 0 : 7 - lastDayOfMonth
      const dates = []
      for (let i = 1; i <= daysInMonth; i++) {
        dates.push({ value: i, classes: '' })
      }
      // for (let i = 1; i <= daysAfterMonth && dates.length < 42; i++) {
      //   dates.push({ value: '', classes: 'next-month' })
      // }
      this.dates = [...this.dates, ...dates]
      this.today = new Date().getDate()
      const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      this.monthName = MONTH_NAMES[this.month]
    }
  },
  mounted () {
    const now = new Date()
    this.month = now.getMonth()
    this.currentMonth = now.getMonth()
    this.year = now.getFullYear()
    this.currentYear = now.getFullYear()
    this.updateDates()
  }
}
</script>

<style lang="scss" scoped>
.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 10px;
  margin-top: 15px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;

  h2 {
    font-weight: 400;
    font-size: 16px;
  }

  button {
    background: transparent;
    border: none;
    background: #ebebeb;
    border-radius: 10px;
    width: 60px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
  .prev svg {
    transform: rotate(180deg);
  }
}

.daysWrap {
  width: 60px;
  height: 20px;
  cursor: pointer;
}
.daysWrap.disabled {
  pointer-events: none;
  cursor: default;
}

.days-of-week .first-day {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.days-of-week .last-day {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.days-of-week {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  div {
    width: calc(100% / 7);
  }
}

.dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.dates .days {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 64px;
  padding: 5px;
  margin-top: 5px;

  // div &:hover {
  //   background: #47573e !important;
  //   border-radius: 10px;
  //   color: #ffffff;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 40px;
  //   width: 54px;
  // }
}

.today {
  background: #47573e !important;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 54px;
}

.selected {
  background: #8cb4b1 !important;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 54px;
}

.prev-month,
.next-month {
  opacity: 0.5;
  cursor: pointer;
  color: #bbb;
}

.prev-month:hover,
.next-month:hover {
  opacity: 1;
}

.prev-month {
  visibility: hidden;
}

.calendar:hover .prev-month {
  visibility: visible;
}

.next-month {
  visibility: hidden;
  //   display: none!important;
}

.calendar:hover .next-month {
  visibility: visible;
}
@media (max-width: $break-md) {
  .calendar {
    .dates .days {
      height: 31px;
      width: 48px;
      padding: 5px;
      margin-top: 5px;
      font-size: 15px;
    }
  }
}
</style>
